import Banner from "../elements/Banner";
import { GelleryTextSign } from "../assets/utilities/GelleryTextSign";
import { Cornerstones4 } from "../assets/utilities/Cornerstones4";
import { Cornerstones2 } from "../assets/utilities/Cornerstones2";
import { Cornerstones1 } from "../assets/utilities/Cornerstones1";
import { Cornerstones5 } from "../assets/utilities/Cornerstones5";
import { Cornerstones3 } from "../assets/utilities/Cornerstones3";
import { Cornerstones6 } from "../assets/utilities/Cornerstones6";
import { ServiceCow } from "../assets/utilities/ServiceCow";
import "./About.scss";

function About(): JSX.Element {
    function Service(props: { indexes: number }) {
        var service_h1, service_image1;
        switch (props.indexes) {
            case 1:
                service_h1 = "Strategy";
                service_image1 = <img className="rotate-left" src={Cornerstones4} alt="Cornerstones4" />;
                break;
            case 2:
                service_h1 = "Website Services";
                service_image1 = <img className="rotate-left" src={Cornerstones2} alt="Cornerstones2" />;
                break;
            case 3:
                service_h1 = "Branding & Communications";
                service_image1 = <img className="rotate-left" src={Cornerstones1} alt="Cornerstones1" />;
                break;
            case 4:
                service_h1 = "Advertising";
                service_image1 = <img className="rotate-right" src={Cornerstones5} alt="Cornerstones5" />;
                break;
            case 5:
                service_h1 = "Content Creation";
                service_image1 = <img className="rotate-right" src={Cornerstones3} alt="Cornerstones3" />;
                break;
            case 6:
                service_h1 = "Technical Development";
                service_image1 = <img className="rotate-left" src={Cornerstones2} alt="Cornerstones2" />;
                break;
            case 7:
                service_h1 = "Marketing";
                service_image1 = <img className="rotate-left" src={Cornerstones6} alt="Cornerstones6" />;
                break;
        }
        return (
            <div className="row service p-4 my-5">
                <div className="col-sm-6 first-half">
                    <div>
                        {service_image1}
                        <h1>{service_h1}</h1>
                        <p className="pt-3">
                            Flume copy - placeholder. Thriving businesses focus
                            on customer experience. We can create a
                            comprehensive digital transformation strategy,
                            outlining future technology that connects entire
                            ecosystem. Business is about to get a whole lot
                            better.
                        </p>
                        <a href="#">
                            <span>!</span> Learn more about our Website
                            Development service
                        </a>
                        <h4 className="pt-5 pb-2">
                            Our strategy services include
                        </h4>
                        <div>
                            <ul>
                                <li>Website Development</li>
                                <li>Shopify Development</li>
                                <li>Craft CMS Development</li>
                                <li>Wordpress Development</li>
                                <li>Webflow Development</li>
                                <li>Laravel + vuejs</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 second-half p-3">
                    <img src={ServiceCow} alt="ServiceCow" />
                </div>
            </div>
        );
    }
    return (
        <>
            <Banner page="about" />
            <div className="sevices-outline-container py-5">
                <div className="container">
                    <div className="gallery-text my-4">
                        <h2>
                            <span>Extra text </span>
                            available if Lucy or Nate would like to frame up
                            the&nbsp;
                            <span>
                                individual service <img src={GelleryTextSign} />
                            </span>
                            blocks with added context
                        </h2>
                    </div>
                </div>
                <div className="container">
                    <Service indexes={1} />
                    <Service indexes={2} />
                    <Service indexes={3} />
                    <Service indexes={4} />
                    <Service indexes={5} />
                    <Service indexes={6} />
                    <Service indexes={7} />
                </div>
            </div>
        </>
    );
}

export default About;
