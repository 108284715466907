import { useEffect, useRef } from "react";
import { WorkBanner1 } from "../assets/utilities/WorkBanner1";
import { WorkBanner2 } from "../assets/utilities/WorkBanner2";
import { WorkBanner3 } from "../assets/utilities/WorkBanner3";
import { WorkBanner4 } from "../assets/utilities/WorkBanner4";
import "./WorkBanner.scss";
function WorkBanner(): JSX.Element {
    const workBannerContainer = useRef<HTMLInputElement>(null);
    const workBannerAnimation = () => {
        const work_banner_height = workBannerContainer?.current
            ?.querySelector("img:nth-child(2)")
            ?.getBoundingClientRect();
        if (work_banner_height && workBannerContainer?.current) {
            const workBannerContainerOffset =
                workBannerContainer?.current?.offsetTop -
                work_banner_height.height - window.innerHeight / 6;
            if (
                window.pageYOffset > workBannerContainerOffset &&
                window.pageYOffset <
                    workBannerContainer?.current?.offsetTop - window.innerHeight / 6
            ) {
                let translate = window.pageYOffset - workBannerContainerOffset;
                translate = translate / 2 - 15;
                workBannerContainer?.current
                    ?.querySelector("img:nth-child(1)")
                    ?.setAttribute(
                        "style",
                        "transform: translateY(" +
                            translate +
                            "px); transition: 1000ms"
                    );
                workBannerContainer?.current
                    ?.querySelector("img:nth-child(3)")
                    ?.setAttribute(
                        "style",
                        "transform: translateY(" +
                            translate +
                            "px); transition: 1000ms"
                    );

                workBannerContainer?.current
                    ?.querySelector("img:nth-child(2)")
                    ?.setAttribute(
                        "style",
                        "transform: translateY(-" +
                            translate +
                            "px); transition: 1000ms"
                    );

                const our_work_height  = workBannerContainer?.current?.querySelector("img:nth-child(2)")?.getBoundingClientRect()?.height;
                if(our_work_height)
                workBannerContainer?.current
                    ?.querySelector(".work_banner2-text")
                    ?.setAttribute(
                        "style",
                        "transform: translateY(-" +
                            (translate + our_work_height / 2 + 30) +
                            "px); transition: 1000ms"
                    );
            }
        }
    };
    useEffect(() => {
        console.log("================================");
        workBannerAnimation();
        window.addEventListener("scroll", workBannerAnimation, {
            passive: true,
        });
    }, []);
    return (
        <div className="work_banner-container py-5" ref={workBannerContainer}>
            <img className="work_banner1" src={WorkBanner1} alt="WorkBanner1" />
            <img className="work_banner2" src={WorkBanner2} alt="WorkBanner2" />
            <img className="work_banner3" src={WorkBanner3} alt="WorkBanner3" />
            <div className="work_banner2-text">
                <h1>Our work</h1>
                <a href="#">See more</a>
            </div>
            {/* <img className="work_banner4" src={WorkBanner4} alt="WorkBanner4" /> */}
        </div>
    );
}
export default WorkBanner;
