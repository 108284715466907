import React from "react";
// import ReactDOM from "react-dom";
import ReactDOM from "react-dom/client";

// import './index.css';
// import App from './App';
import Frontend from "./layout/Frontend";
import reportWebVitals from "./reportWebVitals";

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <Frontend />
//   </React.StrictMode>
// );
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <Frontend />
    </React.StrictMode>
);
/** */

// const container = document.getElementById('root');
// // Create a root.
// const root = ReactDOM.createRoot(container);
// // Initial render
// root.render(<React.StrictMode>
//     <Frontend />
// </React.StrictMode>);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
