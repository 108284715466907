// import logo from '../logo.svg';
import React, { useEffect, useRef, useState } from "react";
// import { Link } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.css";
import "./Home.scss";
import { StarIcon } from "../assets/utilities/StarIcon";
import { GojuLogo } from "../assets/utilities/GojuLogo";
import { BrandLogo } from "../assets/utilities/BrandLogo";
import { EnvelopOpen } from "../assets/utilities/EnvelopOpen";
import { CarHome } from "../assets/utilities/CarHome";
import { ServiceDont } from "../assets/utilities/ServiceDont";
import { Cornerstones1 } from "../assets/utilities/Cornerstones1";
import { Cornerstones2 } from "../assets/utilities/Cornerstones2";
import { Cornerstones3 } from "../assets/utilities/Cornerstones3";
import { Cornerstones4 } from "../assets/utilities/Cornerstones4";
import { Cornerstones5 } from "../assets/utilities/Cornerstones5";
import { Cornerstones6 } from "../assets/utilities/Cornerstones6";
import { FastForward } from "../assets/utilities/FastForward";
import { WorkBanner1 } from "../assets/utilities/WorkBanner1";
import { WorkBanner2 } from "../assets/utilities/WorkBanner2";
import { WorkBanner3 } from "../assets/utilities/WorkBanner3";
import { WorkBanner4 } from "../assets/utilities/WorkBanner4";
import { GelleryTextSign } from "../assets/utilities/GelleryTextSign";
import { ProjectTv } from "../assets/utilities/ProjectTv";
import { FooterCartoon } from "../assets/utilities/FooterCartoon";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Banner from "../elements/Banner";
import WorkBanner from "../elements/WorkBanner";

function Home(): JSX.Element {
    // const [offset, setOffset] = useState(0);
    // window.document.querySelector('.work_banner-container').offsetTop;
    // debugger;
    const NextArrow = (props: {
        className?: any;
        style?: any;
        onClick?: any;
    }): JSX.Element => {
        const { className, style, onClick } = props;
        // console.log(className);
        return (
            <div className={"custome_next_arrow"} onClick={onClick}>
                <FontAwesomeIcon icon={faArrowRight} />
            </div>
        );
    };

    const PrevArrow = (props: {
        className?: any;
        style?: any;
        onClick?: any;
    }): JSX.Element => {
        const { className, style, onClick } = props;
        return (
            <div className={"custome_prev_arrow"} onClick={onClick}>
                <FontAwesomeIcon icon={faArrowLeft} />
            </div>
        );
    };
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ],
        prevArrow: <PrevArrow />,
        // nextArrow: <div className="slick-next"><i className="fa fa-arrow-right" aria-hidden="true"></i></div>,
        nextArrow: <NextArrow />,
    };

    const homeCardContainerCol = useRef<HTMLDivElement>(null); 
    const homeCardEffect = function () {
        const row = homeCardContainerCol.current?.getBoundingClientRect();
        if (row && window.innerWidth >= 1200) {
            const row_top = row?.top;
            const row_bottom = row?.bottom - window.outerHeight + 200;
            if (row_top < 0 && row_bottom > 0) {
                    if(homeCardContainerCol &&  homeCardContainerCol.current !== null) {
                        const homeCardContainer1 = homeCardContainerCol.current.querySelector<HTMLDivElement>(
                            ".Home-card-container-1"
                        );
                        if(homeCardContainer1 && homeCardContainer1 !== null) {
                            let temp_row_top = row_top * -1;
                            homeCardContainer1.style.transform = "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, "+temp_row_top+", 0, 1)";
                        }
                    }
                    let cols =
                        homeCardContainerCol?.current?.querySelectorAll<HTMLDivElement>(
                            ".Home-card-container-col"
                        );
                    if (cols && cols !== null && typeof cols !== undefined  && cols.length > 0) {
                        const row_diff = row.bottom - row.top;
                        const number_of_cols = cols.length + 2;
                        const total_cols = row_diff / number_of_cols;
                        cols?.forEach(function (e: HTMLDivElement, i: number) {
                            // const col = e;
                            // console.log(e);
                            const row_top_positive = row_top * -1;
                            const row_top_positive1 = total_cols * (i+1);
                            // const row_top_positive1 = ;
                            // console.log(row_top_positive, row_top_positive1);
                            if(row_top_positive > row_top_positive1) {
                                let percent = row_top_positive1 * 100/row_top_positive - 33.33 * i;
                                console.log('percent'+i, percent);
                                percent = percent > 33.33 ? percent: 33.33;
                                const col_percent = 'calc('+percent+'% - 20px)';
                                e.style.minWidth = col_percent;
                            } else {
                                const col_percent = 'calc(100% - 20px)';
                                e.style.minWidth = col_percent;
                            }
                            //total_cols * i
                            // console.log('-----', i, total_cols * i, row_top, row_bottom);
                            // console.log('row_diff', row_diff, total_cols);

                            // console.log(e);
                            /*
                    const service1 = e;
                    const service1_offset = service1?.getBoundingClientRect();
                    if (service1_offset?.top) {
                        const service_1_top =
                            service1_offset?.top + 50 - window.outerHeight;
                        const service_1_bottom = service1_offset?.bottom;
                        // console.log(service_1_top+' < 0 '+service_1_bottom+' > 0')
                        if (service_1_top < 0 && service_1_bottom > 0) {
                            if (
                                !service1
                                    ?.querySelector(".second-half")
                                    ?.classList?.contains("right_to_left")
                            ) {
                                service1
                                    ?.querySelector(".second-half")
                                    ?.classList?.add("right_to_left");
                            }
                            if (
                                !service1
                                    ?.querySelector(".first-half")
                                    ?.classList?.contains("left_to_right")
                            ) {
                                service1
                                    ?.querySelector(".first-half")
                                    ?.classList?.add("left_to_right");
                            }
                        } else {
                            service1
                                ?.querySelector(".second-half")
                                ?.classList?.remove("right_to_left");
                            service1
                                ?.querySelector(".first-half")
                                ?.classList?.remove("left_to_right");
                        }
                    }
                    /** */
                        });
                    }
                
            }
        } else {
            // no need animation bellow 1200
            let min_width = "";
            console.log("OK");
            if(window.innerWidth < 768){
                min_width = "calc(50% - 20px)";
            } else {
                min_width = "calc(33.33% - 20px)";
            }
            // console.log(homeCardContainerCol?.current, min_width);
            // homeCardContainerCol?.current?.style.height = "";
            homeCardContainerCol?.current?.querySelectorAll<HTMLDivElement>(
                ".Home-card-container-col"
            ).forEach(function (e: HTMLDivElement, i: number) {
                e.style.minWidth = min_width;
            });
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", homeCardEffect, { passive: true });
    }, []);

    return (
        <>
            <Banner page="home" />
            {/* <div className="container"> */}
                
            {/* </div> */}

            <div className="container">
                <div ref={homeCardContainerCol} className="Home-card-container">
                        <div className="Home-card-container-1">
                            <div className="row py-5">
                                <div className="col-sm-9 py-5">
                                    <p className="text-white display-6">
                                        This is a&nbsp;
                                        <a href="" className="text-white">
                                            basic outline
                                        </a>
                                        &nbsp; about backbone. Think of it as a too long;
                                        didn’t read snapshot of the agency. A brief summary
                                        that will give a lot of broad strokes.
                                    </p>
                                </div>
                            </div>
                            <div className="Home-card-container-row">
                                <div className="Home-card-container-col">
                                    <div className="Home-card">
                                        <p>01 / 03</p>
                                        <h5 className="cardHeading">
                                            PROJECT<span>BASED</span>
                                        </h5>
                                        <p>
                                            Think of this as a first date or a one night
                                            stand. Lets get to know each other, no
                                            commitments, just one project with clear
                                            deliverables.
                                        </p>
                                        <a href="#">
                                            <span>!</span> Learn More
                                        </a>
                                    </div>
                                </div>
                                <div className="Home-card-container-col">
                                    <div className="Home-card">
                                        <p>01 / 03</p>
                                        <h5 className="cardHeading">
                                            PARTNERSHIP<span>PROGRAM</span>
                                        </h5>
                                        <p>
                                            Think of this as a first date or a one night
                                            stand. Lets get to know each other, no
                                            commitments, just one project with clear
                                            deliverables.
                                        </p>
                                        <a href="#">
                                            <span>!</span> Learn More
                                        </a>
                                    </div>
                                </div>
                                <div className="Home-card-container-col">
                                    <div className="Home-card">
                                        <p>01 / 03</p>
                                        <h5 className="cardHeading">
                                            VENTURE<span>PROJECT</span>
                                        </h5>
                                        <p>
                                            Think of this as a first date or a one night
                                            stand. Lets get to know each other, no
                                            commitments, just one project with clear
                                            deliverables.
                                        </p>
                                        <a href="#">
                                            <span>!</span> Learn More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>

            <div className="container">
                <div className="row py-5 justify-content-end">
                    <div className="col-sm-9">
                        <p className="text-white display-6">
                            This is a&nbsp;
                            <a href="" className="text-white">
                                basic outline
                            </a>
                            &nbsp; about backbone. Think of it as a too long;
                            didn’t read snapshot of the agency. A brief summary
                            that will give a lot of broad strokes.
                        </p>
                        <a
                            href="#"
                            className="btn btn-outline-primary border-white text-white p-2 px-5 rounded-5 ml-2"
                        >
                            Work with us
                        </a>
                    </div>
                </div>
            </div>
            <div className="sevices-outline-container py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 py-5">
                            <h2>
                                We
                                <span>
                                    don’t <img src={ServiceDont} />
                                </span>
                                just stick to the basics
                            </h2>
                            <p>
                                This is a short breakdown of our services,
                                possibly instructions on how to find out more.
                            </p>
                            <p className="fast-forward">
                                <img src={FastForward} /> Fast Forward to
                                Services
                            </p>
                        </div>
                        <div className="col-sm-9">
                            <div className="row cornerstones m-0">
                                <div className="col-md-6 p-5">
                                    <img
                                        src={Cornerstones1}
                                        alt="Cornerstones1"
                                    />
                                    <h2>Branding & Communications</h2>
                                    <p>
                                        This is a short statement about what we
                                        do. It should intrigue and inform,
                                        prompting the user to continue scrolling
                                    </p>
                                    <a href="#">
                                        <span>!</span> Learn More
                                    </a>
                                </div>
                                <div className="col-md-6 p-5">
                                    <img
                                        src={Cornerstones2}
                                        alt="Cornerstones2"
                                    />
                                    <h2>Website Development</h2>
                                    <p>
                                        This is a short statement about what we
                                        do. It should intrigue and inform,
                                        prompting the user to continue scrolling
                                    </p>
                                    <a href="#">
                                        <span>!</span> Learn More
                                    </a>
                                </div>
                                <div className="col-md-6 p-5">
                                    <img
                                        src={Cornerstones3}
                                        alt="Cornerstones3"
                                    />
                                    <h2>Content & Creative</h2>
                                    <p>
                                        This is a short statement about what we
                                        do. It should intrigue and inform,
                                        prompting the user to continue scrolling
                                    </p>
                                    <a href="#">
                                        <span>!</span> Learn More
                                    </a>
                                </div>
                                <div className="col-md-6 p-5">
                                    <img
                                        src={Cornerstones4}
                                        alt="Cornerstones4"
                                    />
                                    <h2>Business Strategy</h2>
                                    <p>
                                        This is a short statement about what we
                                        do. It should intrigue and inform,
                                        prompting the user to continue scrolling
                                    </p>
                                    <a href="#">
                                        <span>!</span> Learn More
                                    </a>
                                </div>
                                <div className="col-md-6 p-5">
                                    <img
                                        src={Cornerstones5}
                                        alt="Cornerstones5"
                                    />
                                    <h2>Advertising</h2>
                                    <p>
                                        This is a short statement about what we
                                        do. It should intrigue and inform,
                                        prompting the user to continue scrolling
                                    </p>
                                    <a href="#">
                                        <span>!</span> Learn More
                                    </a>
                                </div>
                                <div className="col-md-6 p-5">
                                    <img
                                        src={Cornerstones6}
                                        alt="Cornerstones6"
                                    />
                                    <h2>Marketing</h2>
                                    <p>
                                        This is a short statement about what we
                                        do. It should intrigue and inform,
                                        prompting the user to continue scrolling
                                    </p>
                                    <a href="#">
                                        <span>!</span> Learn More
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <WorkBanner />
                    </div>
                </div>
                <div className="container">
                    <div className="gallery-text mb-4">
                        <h2>
                            <span>This is a gallery </span>of projects. Text
                            here highlights how we use{" "}
                            <span>
                                classic marketing <img src={GelleryTextSign} />
                            </span>{" "}
                            techniques in a modern way
                        </h2>
                    </div>
                </div>
                <div className="container">
                    <div className="row project-tv-container px-3 py-5 m-0">
                        <div className="col-md-6 first-half">
                            <img
                                className="mb-4"
                                src={GojuLogo}
                                alt="GojuLogo"
                            />
                            <p className="mb-0">
                                We got together with the Goju team to work on...
                            </p>
                            <h1>Website build, print labels + brand refresh</h1>
                            <p className="my-4 font-16">
                                Goju's team is small and they needed to be able
                                to run their eCommerce website with minimal
                                staff. They needed to find tools that they could
                                use to manage the business long-term, yet still
                                be able to enhance it as operations allow.
                                Backbone began working with Goju early in their
                                discovery and helped define
                                requirements/features.
                            </p>
                            <a href="">
                                <span>
                                    Learn more{" "}
                                    <img
                                        src={GelleryTextSign}
                                        alt="GelleryTextSign"
                                    />
                                </span>
                            </a>
                        </div>
                        <div className="col-md-6 second-half">
                            <div className="row">
                                <button className="active">
                                    Project Based
                                </button>
                                <button>Branding</button>
                                <button>Web design</button>
                            </div>
                            <div>
                                <p>PLUS three more services</p>
                            </div>
                            <div>
                                <img
                                    className="project-tv"
                                    src={ProjectTv}
                                    alt="ProjectTv"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="gallery-text my-4">
                        <h2>
                            <span>This is a gallery </span>of projects. Text
                            here highlights how we use&nbsp;
                            <span>
                                classic marketing <img src={GelleryTextSign} />
                            </span>
                            techniques in a modern way
                        </h2>
                    </div>
                </div>
                <div className="container">
                    <div className="slider-image-container">
                        <Slider {...settings}>
                            <div>
                                <div className="image">
                                    <img src={GojuLogo} />
                                </div>
                            </div>
                            <div>
                                <div className="image">
                                    <img src={GojuLogo} />
                                </div>
                            </div>
                            <div>
                                <div className="image">
                                    <img src={GojuLogo} />
                                </div>
                            </div>
                            <div>
                                <div className="image">
                                    <img src={GojuLogo} />
                                </div>
                            </div>
                            <div>
                                <div className="image">
                                    <img src={GojuLogo} />
                                </div>
                            </div>
                            <div>
                                <div className="image">
                                    <img src={GojuLogo} />
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;
