// import React, { useEffect, useRef, useState } from "react";
// import "bootstrap/dist/css/bootstrap.css";

import "bootstrap/scss/bootstrap.scss";
// import "bootstrap/scss/_variables.scss";
import "bootstrap/dist/js/bootstrap";
import "./Frontend.scss";
import { StarIcon } from "../assets/utilities/StarIcon";
import { GojuLogo } from "../assets/utilities/GojuLogo";
import { BrandLogo } from "../assets/utilities/BrandLogo";
import { EnvelopOpen } from "../assets/utilities/EnvelopOpen";

// import { CarHome } from "../assets/utilities/CarHome";
// import { ServiceDont } from "../assets/utilities/ServiceDont";
// import { Cornerstones1 } from "../assets/utilities/Cornerstones1";
// import { Cornerstones2 } from "../assets/utilities/Cornerstones2";
// import { Cornerstones3 } from "../assets/utilities/Cornerstones3";
// import { Cornerstones4 } from "../assets/utilities/Cornerstones4";
// import { Cornerstones5 } from "../assets/utilities/Cornerstones5";
// import { Cornerstones6 } from "../assets/utilities/Cornerstones6";
// import { FastForward } from "../assets/utilities/FastForward";
// import { WorkBanner1 } from "../assets/utilities/WorkBanner1";
// import { WorkBanner2 } from "../assets/utilities/WorkBanner2";
// import { WorkBanner3 } from "../assets/utilities/WorkBanner3";
// import { WorkBanner4 } from "../assets/utilities/WorkBanner4";
// import { GelleryTextSign } from "../assets/utilities/GelleryTextSign";
// import { ProjectTv } from "../assets/utilities/ProjectTv";
// import { FooterCartoon } from "../assets/utilities/FooterCartoon";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CaseStudyDetail from "../pages/CaseStudyDetail";
import BlogDetail from "../pages/BlogDetail";
import Home from "../pages/Home";
import About from "../pages/About";
import Services from "../pages/Services";
import WebsiteServices from "../pages/WebsiteServices";
import ShopifyDevelopment from "../pages/ShopifyDevelopment";
import Footer from "./elements/Footer";
// import { Link } from "react-router-dom";
// import { Outlet } from "react-router-dom";
import { BrowserRouter as Router, Routes, Link, Route } from "react-router-dom";
import { useRef, useEffect, useState } from "react";
import Blog from "../pages/Blog";
import CaseStudy from "../pages/CaseStudy";
function Frontend(): JSX.Element {
  // const [navAnimationClass, setNavAnimationClass] = useState<String>('');
  const mainNav = useRef<HTMLInputElement>(null);
  const navAnimation = () => {
    if (window.innerWidth > 992) {
      if (window.pageYOffset > 26) {
        mainNav?.current?.classList.remove("navbar-expand-lg");
        mainNav?.current?.classList.add("animation");
      } else {
        mainNav?.current?.classList.add("navbar-expand-lg");
        mainNav?.current?.classList.remove("animation");
      }
    } else {
      mainNav?.current?.classList.remove("navbar-expand-lg");
      mainNav?.current?.classList.remove("animation");
    }
  };
  useEffect(() => {
    navAnimation();
    window.addEventListener("scroll", navAnimation, { passive: true });
  }, []);

  return (
    <Router>
      <div className="frontend">
        <div className="hellobar container-fluid">
          <div>
            <div className="message-marque">
              <div className="project">
                <div>
                  <Link to={"/"}>
                    <img src={GojuLogo} alt="" />
                  </Link>
                </div>
                <div>
                  <span>
                    Branding and new website
                    <a href="">View Project</a>
                  </span>
                </div>
              </div>
              <div className="rating-container">
                <div>
                  <img src={StarIcon} alt="" />
                  <img src={StarIcon} alt="" />
                  <img src={StarIcon} alt="" />
                  <img src={StarIcon} alt="" />
                  <img src={StarIcon} alt="" />
                </div>
                <div>
                  <span>
                    25+ 5-star customer reviews from all over the world
                  </span>
                </div>
              </div>
              <div className="result">
                <div>
                  <span>125 million +</span>
                </div>
                <span>Revenue generated for course clients to date</span>
              </div>
            </div>
            <div className="message-marque">
              <div className="project">
                <div>
                  <img src={GojuLogo} alt="" />
                </div>
                <div>
                  <span>
                    Branding and new website
                    <a href="">View Project</a>
                  </span>
                </div>
              </div>
              <div className="rating-container">
                <div>
                  <img src={StarIcon} alt="" />
                  <img src={StarIcon} alt="" />
                  <img src={StarIcon} alt="" />
                  <img src={StarIcon} alt="" />
                  <img src={StarIcon} alt="" />
                </div>
                <div>
                  <span>
                    25+ 5-star customer reviews from all over the world
                  </span>
                </div>
              </div>
              <div className="result">
                <div>
                  <span>125 million +</span>
                </div>
                <span>Revenue generated for course clients to date</span>
              </div>
            </div>
          </div>
        </div>
        <nav
          className="navbar navbar-expand-lg navbar-dark pt-5 pb-3 mt-4"
          id="mainNav"
          ref={mainNav}
        >
          <div className="container-fluid position-relative">
            <Link className="navbar-brand" to={"/"}>
              <img src={BrandLogo} alt="" />
            </Link>
            <button
              className="navbar-toggler navbar-toggler-right"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ms-auto my-2 my-lg-0">
                <li className="nav-item">
                  {/* <Link to="/about" className="nav-link">
                                        About
                                    </Link> */}
                  <a className="nav-link" href="#About">
                    About
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#TrackRecord">
                    Track Record
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#Ethos">
                    Ethos
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#Venture">
                    Venture
                  </a>
                </li>
                <li className="nav-item">
                  <Link to="/services" className="nav-link">
                    Services
                  </Link>
                </li>
                {/* <li className="nav-item">
                                    <a className="nav-link" href="#portfolio">
                                        Portfolio
                                    </a>
                                </li> */}
              </ul>
              <ul className="navbar-nav ms-auto my-2 my-lg-0">
                <li className="nav-item">
                  <a className="nav-link" href="#contact">
                    <img src={EnvelopOpen} alt="" />
                    <span>Contact us</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/blogdetail/:slug" element={<BlogDetail />} />
          <Route
            path="/services/website-services"
            element={<WebsiteServices />}
          />
          <Route
            path="/services/shopify-development"
            element={<ShopifyDevelopment />}
          />
          <Route path="/blogs" element={<Blog />} />
          <Route path="/case-study" element={<CaseStudy />} />
          <Route path="/case-study-detail" element={<CaseStudyDetail />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default Frontend;
