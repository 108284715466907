import Banner from "../elements/Banner";
import { GelleryTextSign } from "../assets/utilities/GelleryTextSign";
import { Cornerstones4 } from "../assets/utilities/Cornerstones4";
import { Cornerstones2 } from "../assets/utilities/Cornerstones2";
import { Cornerstones1 } from "../assets/utilities/Cornerstones1";
import { Cornerstones5 } from "../assets/utilities/Cornerstones5";
import { Cornerstones3 } from "../assets/utilities/Cornerstones3";
import { Cornerstones6 } from "../assets/utilities/Cornerstones6";
import { ServiceCow } from "../assets/utilities/ServiceCow";
import "./Services.scss";
import { Link } from "react-router-dom";
import React, { useEffect, useRef } from "react";

function Services(): JSX.Element {
    function ServiceRow(props: { indexes: number }) {
        var service_h1, service_image1, service_link;
        switch (props.indexes) {
            case 1:
                service_h1 = "Strategy";
                service_image1 = (
                    <img
                        className="rotate-left"
                        src={Cornerstones4}
                        alt="Cornerstones4"
                    />
                );
                service_link = (
                    <Link to={"/services/website-services"}>
                        <span>!</span> Learn more about our Website Development
                        service
                    </Link>
                );
                break;
            case 2:
                service_h1 = "Website Services";
                service_image1 = (
                    <img
                        className="rotate-left"
                        src={Cornerstones2}
                        alt="Cornerstones2"
                    />
                );
                service_link = (
                    <Link to={"/services/website-services"}>
                        <span>!</span> Learn more about our Website Development
                        service
                    </Link>
                );
                break;
            case 3:
                service_h1 = "Branding & Communications";
                service_image1 = (
                    <img
                        className="rotate-left"
                        src={Cornerstones1}
                        alt="Cornerstones1"
                    />
                );
                service_link = (
                    <Link to={"/services/website-services"}>
                        <span>!</span> Learn more about our Website Development
                        service
                    </Link>
                );
                break;
            case 4:
                service_h1 = "Advertising";
                service_image1 = (
                    <img
                        className="rotate-right"
                        src={Cornerstones5}
                        alt="Cornerstones5"
                    />
                );
                service_link = (
                    <Link to={"/services/website-services"}>
                        <span>!</span> Learn more about our Website Development
                        service
                    </Link>
                );
                break;
            case 5:
                service_h1 = "Content Creation";
                service_image1 = (
                    <img
                        className="rotate-right"
                        src={Cornerstones3}
                        alt="Cornerstones3"
                    />
                );
                service_link = (
                    <Link to={"/services/website-services"}>
                        <span>!</span> Learn more about our Website Development
                        service
                    </Link>
                );
                break;
            case 6:
                service_h1 = "Technical Development";
                service_image1 = (
                    <img
                        className="rotate-left"
                        src={Cornerstones2}
                        alt="Cornerstones2"
                    />
                );
                service_link = (
                    <Link to={"/services/website-services"}>
                        <span>!</span> Learn more about our Website Development
                        service
                    </Link>
                );
                break;
            case 7:
                service_h1 = "Marketing";
                service_image1 = (
                    <img
                        className="rotate-left"
                        src={Cornerstones6}
                        alt="Cornerstones6"
                    />
                );
                service_link = (
                    <Link to={"/services/website-services"}>
                        <span>!</span> Learn more about our Website Development
                        service
                    </Link>
                );
                break;
        }
        return (
            <div className="row service p-2 mx-0">
                <div className="col-sm-6 first-half">
                    <div>
                        {service_image1}
                        <h1>{service_h1}</h1>
                        <p className="pt-3">
                            Flume copy - placeholder. Thriving businesses focus
                            on customer experience. We can create a
                            comprehensive digital transformation strategy,
                            outlining future technology that connects entire
                            ecosystem. Business is about to get a whole lot
                            better.
                        </p>
                        {/* <a href="#">
                            <span>!</span> Learn more about our Website
                            Development service
                        </a> */}
                        {service_link}
                        <h4 className="pt-5 pb-2">
                            Our strategy services include
                        </h4>
                        <div>
                            <ul>
                                <li>
                                    <Link to={"/services/website-services"}>
                                        Website Development
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/services/shopify-development"}>
                                        Shopify Development
                                    </Link>
                                </li>
                                <li>Craft CMS Development</li>
                                <li>Wordpress Development</li>
                                <li>Webflow Development</li>
                                <li>Laravel + vuejs</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 second-half p-3">
                    <img src={ServiceCow} alt="ServiceCow" />
                </div>
            </div>
        );
    }
    const serviceContainerAll = useRef<HTMLInputElement>(null);

    const slide = function () {
        let services =
            serviceContainerAll?.current?.querySelectorAll(".service");
        if (services && services.length > 0) {
            services?.forEach(function (e, i) {
                const service1 = e;
                const service1_offset = service1?.getBoundingClientRect();
                if (service1_offset?.top) {
                    const service_1_top =
                        service1_offset?.top + 50 - window.outerHeight;
                    const service_1_bottom = service1_offset?.bottom;
                    // console.log(service_1_top+' < 0 '+service_1_bottom+' > 0')
                    if (service_1_top < 0 && service_1_bottom > 0) {
                        if (
                            !service1
                                ?.querySelector(".second-half")
                                ?.classList?.contains("right_to_left")
                        ) {
                            service1
                                ?.querySelector(".second-half")
                                ?.classList?.add("right_to_left");
                        }
                        if (
                            !service1
                                ?.querySelector(".first-half")
                                ?.classList?.contains("left_to_right")
                        ) {
                            service1
                                ?.querySelector(".first-half")
                                ?.classList?.add("left_to_right");
                        }
                    } else {
                        service1
                            ?.querySelector(".second-half")
                            ?.classList?.remove("right_to_left");
                        service1
                            ?.querySelector(".first-half")
                            ?.classList?.remove("left_to_right");
                    }
                }
            });
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", slide, { passive: true });
    }, []);

    return (
        <div className="serviecs">
            <Banner page="services" />
            <div className="sevices-outline-container py-5">
                <div className="container">
                    <div className="gallery-text my-4">
                        <h2>
                            <span>Extra text </span>
                            available if Lucy or Nate would like to frame up
                            the&nbsp;
                            <span>
                                individual service <img src={GelleryTextSign} />
                            </span>
                            blocks with added context
                        </h2>
                    </div>
                </div>
                <div className="container" ref={serviceContainerAll}>
                    <ServiceRow indexes={1} />
                    <ServiceRow indexes={2} />
                    <ServiceRow indexes={3} />
                    <ServiceRow indexes={4} />
                    <ServiceRow indexes={5} />
                    <ServiceRow indexes={6} />
                    <ServiceRow indexes={7} />
                </div>
            </div>
        </div>
    );
}

export default Services;
